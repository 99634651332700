<template>
  <div class="column is-8">
    <faq-item v-for="faq in faqs" :key="`faq-${faq.id}`" :faq="faq"></faq-item>
    <!-- 페이지네이션 -->
    <lp-paginate :filtered="faqFiltered" @getData="getData"></lp-paginate>
  </div>
</template>

<script>
  import FaqItem from "./FaqItem";
  import LpPaginate from "../component/LpPaginate";

  export default {
    components: {
      LpPaginate,
      FaqItem},
    name: "faq",
    created() {
      this.getData();
    },
    methods: {
      getData () {
        this.$axios.get('public/board/faq', {
          params: this.faqFiltered
        }).then(res=>{
          this.faqs = res.data.data;
          this.faqFiltered.page_length = res.data.page_length;
          this.faqFiltered.cur_page = res.data.cur_page;
          this.faqFiltered.total_page = res.data.total_page;
        });
      }
    },
    data() {
      return {
        faqs: [],
        faqFiltered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 9
        }
      }
    }
  }
</script>

<style scoped>

</style>
